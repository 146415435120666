import { AmpPlugin, DateTime, LockPlugin, RangePlugin, easepick } from '@easepick/bundle'
export default class BookingForm {
    constructor() {
        BookingForm.bookingModal()
        BookingForm.bookingForm()
    }

    static bookingModal() {
        $(document).on('click', '[data-trigger-modal-customer]', function (e) {
            e.preventDefault()
            const idModal = $(this).attr('href')
            $(idModal).toggleClass('is-active')
        })
    }

    static bookingForm() {
        if ($('.engine-booking').length > 0) {
            $('[data-booking-form="hebergement"]').each(function (e) {
                const dateFromElement = $(this).find('.booking-form__datepicker-from')
                const dateToElement = $(this).find('.booking-form__datepicker-to')
                let startDate = new Date().toISOString().slice(0, 10)
                let endDate = new Date().toISOString().slice(0, 10)

                const picker = new easepick.create({
                    element: $(dateFromElement)[0],
                    format: 'DD/MM/YYYY',
                    css: [
                        'https://cdn.jsdelivr.net/npm/@easepick/bundle@1.2.1/dist/index.css',
                        IRISCollectionCustomer.styleCustomerEasepick,
                    ],
                    setup(picker) {
                        picker.on('select', (e) => {
                            startDate = e.detail.start.format('YYYY-MM-DD')
                            endDate = e.detail.end.format('YYYY-MM-DD')
                        })
                    },

                    inline: false,
                    calendars: 2,
                    grid: 2,
                    zIndex: 10,
                    lang: 'fr-FR',
                    AmpPlugin: {
                        resetButton: true,
                    },
                    LockPlugin: {
                        minDate: new Date(),
                    },
                    RangePlugin: {
                        tooltip: false,
                        elementEnd: $(dateToElement)[0],
                        startDate: new Date(),
                        endDate: new Date(),
                    },
                    plugins: [LockPlugin, AmpPlugin, RangePlugin],
                })

                // Form submit
                $(this).submit(function (e) {
                    e.preventDefault()

                    const values = {}

                    $.each($(this).serializeArray(), (i, field) => {
                        values[field.name] = field.value
                    })

                    if (values.url && startDate && endDate) {
                        window.location.href = `${values.url}recherche/marketplace/${startDate}/${endDate}/${values.nbperson ? `${values.nbperson}/0/` : '0/0'}`
                    }
                })
            })
        }
    }
}
