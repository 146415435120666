export default class Navbar {
    constructor() {
        Navbar.sticky()
        Navbar.burger()
        Navbar.langsSwitcher()
    }

    static sticky() {
        let last_scroll_top = 0

        $(window).on('load scroll', function () {
            const offset = 120 //Trigger offset (~header height)
            const navbar = $('[data-id="wpiris-navbar"]')
            const scroll_top = $(this).scrollTop()
            let full = false

            //Hidden comportment
            if (scroll_top >= last_scroll_top && scroll_top > navbar.outerHeight()) {
                $('body').addClass('header-is-hidden')
            } else {
                $('body').removeClass('header-is-hidden')
            }

            // Detect if header is on banner/video mode
            if ($('.header--with-banner').length > 0) {
                full = true
            }

            last_scroll_top = scroll_top

            if (scroll_top > offset) {
                navbar.removeClass('header__navbar--ontop')
                navbar.addClass('header__navbar--onscroll')
                if (full === true) {
                    Navbar.changeLogoColor('classic')
                }
            } else {
                navbar.removeClass('header__navbar--onscroll')
                navbar.addClass('header__navbar--ontop')
                if (full === true) {
                    Navbar.changeLogoColor('banner')
                }
            }
        })
    }

    static changeLogoColor(color) {
        const logo = $('[data-id="wpiris-logo"]')
        let logo_banner
        let logo_classic

        logo_banner = IRISCollectionCustomer.imageLogoBanner
        logo_classic = IRISCollectionCustomer.imageLogo

        if (color === 'banner') {
            logo.attr('src', logo_banner)
        } else if (color === 'classic') {
            logo.attr('src', logo_classic)
        }
    }

    static burger() {
        const trigger = '[data-trigger="wpiris-menu-toggle"]'
        const more = '[data-trigger="wpiris-menu-more"]'
        const link = '[data-trigger="wpiris-menu-link"]'
        const prev = '[data-trigger="wpiris-menu-prev"]'
        const submenu = '[data-trigger="wpiris-menu-submenu"]'

        // Tree navigation
        $(link).on('mouseenter', function (e) {
            $('.menu__wrapper .menu__item').removeClass('is-active')
            $(this).parent('.menu__wrapper .menu__item').addClass('is-active')
        })

        $(trigger).on('click', () => {
            $('body').toggleClass('menu-open')
        })

        $(more).on('click', function () {
            $(this).next(submenu).toggleClass('submenu-open')
            $('body').toggleClass('submenuOpen')
        })

        $(prev).on('click', (e) => {
            e.preventDefault()
            $(submenu).removeClass('submenu-open')
            $('body').removeClass('submenuOpen')
        })
    }

    static langsSwitcher() {
        const current_lang = $('.tools__item__languages__current')
        const list_langs = $('.tools__item__languages__langs')

        //Open submenu
        $(current_lang).on('click', () => {
            $(list_langs).toggleClass('tools__item__languages__langs--open')
        })

        //Select lang
        $(list_langs).on('click', () => {
            $(list_langs).toggleClass('tools__item__languages__langs--open')
        })
    }
}
