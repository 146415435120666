import Swiper from '@iris.interactive/handcook/public/scripts/libraries/swiper-11'

export default class SliderComponent {
    slider

    // HTML Element
    trigger = '[data-hc-trigger="slider"]'
    item = '[data-hc-element="slider-item"]'

    // Options
    options = {
        pagination: false,
        arrows: false,
    }

    constructor() {
        $(this.trigger).each((index) => {
            this.slider = $($(this.trigger)[index])
            this.setOptions()
            this.buildDom()
            this.initSwiperTempsForts()
        })
    }

    setOptions() {
        for (const option in this.options) {
            const attr = this.slider.attr(`data-hc-${option}`)
            if (typeof attr !== typeof undefined) {
                if (typeof option === 'boolean') {
                    this.options[option] = attr !== false
                } else {
                    this.options[option] = attr
                }
            }
        }
    }

    buildDom() {
        // On recupère les slide items (element de DOM enfant de premier niveau)
        // et on leur ajoute la classe des slide item
        const items = this.slider.children('*')
        items.addClass('swiper-slide')

        // On ajoute la classe swiper a notre slider,
        // on crée la div wrapper et on insére les slider item dedans
        this.slider.addClass('swiper')
        this.slider.append('<div class="swiper-wrapper"></div>')
        this.slider.find('.swiper-wrapper').append(items)

        // On ajout les différentes div utile en fonction des options passées
        if (this.options.pagination) {
            this.slider.append('<div class="swiper-pagination"></div>')
        }
        if (this.options.arrows) {
            this.slider.append(`<div class="swiper-button-prev"></div>
                            <div class="swiper-button-next"></div>`)
        }
    }

    async initSwiperTempsForts() {
        const tempsFortsSlider = await Swiper.create('#strate--temps-forts__list', {
            slidesPerView: '1',
            spaceBetween: 10,
            loop: true,
            navigation: {
                nextEl: '.swiper-btn-next',
                prevEl: '.swiper-btn-prev',
            },
            breakpoints: {
                650: {
                    slidesPerView: '1',
                    spaceBetween: 10,
                },
                1200: {
                    slidesPerView: '1.2',
                    spaceBetween: 30,
                },
                1450: {
                    slidesPerView: '1.2',
                    spaceBetween: 40,
                },
            },
        })
    }
}
