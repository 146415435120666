import BookingForm from '@scripts/components/bookingform'
import Navbar from '@scripts/components/navbar'
import SliderComponent from './components/SliderComponent'

document.addEventListener('DOMContentLoaded', () => {
    new SliderComponent()
    new Navbar()
    new BookingForm()

    if ($('.home').length > 0) {
        import('@scripts/components/home' /* webpackChunkName: "scripts/home" */).then(
            ({ default: Home }) => {
                new Home()
            },
        )
    }

    if ($('.fullscreen').length > 0) {
        import('@scripts/services/layout' /* webpackChunkName: "scripts/layout" */).then(
            ({ default: Layout }) => {
                new Layout()
            },
        )
    }
})
